define(['app'], function(app) {
	const errorContainer = function() {
		const component = {};

		const _config = {
			selectors: {
				customerEmail: '#customerEmail',
				waitListForm: '#widgetWaitListFormID',
				waitlistSignUp: '.widgetWaitList_signUp',
				emailSignUpForm: '.widgetWaitList_signUpForm',
				errorDesc: '.errorContainer_description'
			},
			attributes: {
				show: 'data-show-error-message'
			}
		}

		const _init = (element) => {
			component.element = element;
			component.customerEmail = document.querySelector(component.config.selectors.customerEmail);
			component.waitListForm = document.querySelector(component.config.selectors.waitListForm);
			component.waitlistSignUp = document.querySelector(component.config.selectors.waitlistSignUp);
			component.emailSignUpForm = document.querySelector(component.config.selectors.emailSignUpForm);
			component.errorDesc = document.querySelector(component.config.selectors.errorDesc);
			component.preventBind();
			return component;
		};

		const _preventBind = function() {
			component.waitListForm.addEventListener('click', function(e){
				if (!component.customerEmail.checkValidity()){
					e.preventDefault();
					component.customerEmail.setAttribute("aria-describedby", "email-signup-error-description");
					component.customerEmail.focus();
					component.waitlistSignUp.setAttribute(component.config.attributes.show, "");
					if(component.customerEmail.validity.valueMissing) {
						component.errorDesc.innerHTML = app.utils.getProperty('valueMissing', 'errorContainer');
					} else {
						component.errorDesc.innerHTML = app.utils.getProperty('notValid', 'errorContainer');
					}
				}
			});
		  };

		component.config = _config;
		component.init = _init;
		component.preventBind = _preventBind;
		return component;
	};
	return errorContainer;
});